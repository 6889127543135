// 38, -96 is around the center of the continental US
import type { MapPinType, ReportingLocation, UserDto } from "../../../../models";

export function findCenterCoordinate(coords: number[][]) {
    if (!coords || !coords.length) {
        return { lat: 38, lng: -96 };
    }

    const filtered = coords.filter((item) => item[0] && item[1]);

    const x = filtered.map((item) => item[0] as number);
    const y = filtered.map((item) => item[1] as number);

    const minX = Math.min(...x);
    const maxX = Math.max(...x);
    const minY = Math.min(...y);
    const maxY = Math.max(...y);

    if (minX === Infinity || maxX === -Infinity || minY === Infinity || maxY === -Infinity) {
        return { lat: 38, lng: -96 };
    }

    return {
        lat: (minX + maxX) / 2,
        lng: (minY + maxY) / 2,
        bounds: {
            north: maxY,
            south: minY,
            east: maxX,
            west: minX,
        },
    };
}

export function getSegment(user: UserDto | undefined, item: ReportingLocation | undefined) {
    if (!user) return "";
    const segments: (string | undefined)[] = [];

    if (!user.clientId) segments.push(item?.client);
    if (!user.channelId) segments.push(item?.channel);
    if (!user.stateId) segments.push(item?.state);

    return segments.filter(Boolean).join("-");
}

export function typeToImage(type: MapPinType, disposition?: string) {
    if (type === "userPin") return "/images/user.png";

    if (!disposition) return "/images/flag-yellow.png";
    else if (disposition === "Good Sale") return "/images/flag-green.png";
    else return "/images/flag-red.png";
}
