import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type FC, type FormEvent, useState } from "react";

import { UsersApi } from "../../api/usersApi";
import { QueryKeys, splitWordsOnUpperCase } from "../../helpers";
import { type UserDto } from "../../models";
import { SelectableUserPermissionFlags } from "../../models/permissions";
import { Button, Checkbox, type SelectOption, TextBox, ToggleButton, useErrorToast, useSuccessToast } from "../basic";
import { ChannelsDropdown } from "../dropdowns/channelsDropdown";
import { ClientsDropdown } from "../dropdowns/clientsDropdown";
import { StatesDropdown } from "../dropdowns/statesDropdown";

interface State {
    email: string;
    password?: string;
    mustChangePassword: boolean;
    firstName: string;
    lastName: string;
    active: boolean;
    isOwner: boolean;
    ownerCode?: string;
    permissionFlags: number | undefined;
}

interface CreateUpdateRepresentativeProps {
    user: Partial<UserDto>;
    setOpen: (open: boolean) => void;
    create: boolean;
}

export const CreateUpdateUser: FC<CreateUpdateRepresentativeProps> = ({ user, setOpen, create }) => {
    const [clientId, setClientId] = useState<number | undefined>(user.clientId);
    const [channelId, setChannelId] = useState<number | undefined>(user.channelId);
    const [stateId, setStateId] = useState<number | undefined>(user.stateId);
    const [state, setState] = useState<State>({
        email: user.email ?? "",
        password: "",
        mustChangePassword: user.mustChangePassword ?? false,
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        active: user.active ?? false,
        isOwner: user.isOwner ?? false,
        ownerCode: user.ownerCode,
        permissionFlags: user.permissionFlags,
    });
    const queryClient = useQueryClient();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const handleEmailChange = (value: string) => {
        const outlookEmailFormatRegex = /^(\w+)\s+.*?(\w+)\s+<([^>]+)>/;
        const match = value.match(outlookEmailFormatRegex);

        if (match) {
            setState({ ...state, firstName: match[1] ?? "", lastName: match[2] ?? "", email: match[3] ?? "" });
        } else {
            setState({ ...state, email: value });
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        userMutation.mutate({
            ...state,
            id: user.id,
            active: state.active,
            isOwner: state.isOwner,
            ownerCode: state.ownerCode,
            permissionFlags: state.permissionFlags,
            clientId,
            channelId,
            stateId,
        } as UserDto);
    };

    const userMutation = useMutation({
        mutationFn: UsersApi.saveUser,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USERS] });
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.USER, user.id] });
            setOpen(false);
            successToast(`${create ? "Create" : "Update"} User`, `User ${create ? "created" : "updated"} successfully`);
        },
        onError: () => {
            errorToast(`${create ? "Create" : "Update"} User`, `User ${create ? "create" : "update"} failed`);
        },
    });

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            <ClientsDropdown
                value={clientId}
                selectVal={(value?: SelectOption) => setClientId(value?.value as number)}
                required
                chooseFirst={create}
                isClearable
            />
            <ChannelsDropdown
                value={channelId}
                selectVal={(value?: SelectOption) => setChannelId(value?.value as number)}
                isClearable
            />
            <StatesDropdown
                value={stateId}
                selectVal={(value?: SelectOption) => setStateId(value?.value as number)}
                isClearable
            />
            <TextBox
                id="email"
                label="Email"
                value={state.email}
                changeHandler={(value: string) => handleEmailChange(value)}
                required
                disabled={!create}
            />
            <div className="grid grid-cols-4 gap-4">
                <TextBox
                    id="password"
                    label="Password"
                    value={state.password}
                    type="password"
                    autoComplete="new-password"
                    changeHandler={(value: string) =>
                        setState({
                            ...state,
                            password: value,
                            mustChangePassword: create && !value ? true : state.mustChangePassword,
                        })
                    }
                    outerClassName="col-span-3"
                />
                <Checkbox
                    id="mustChangePassword"
                    label="Must Change"
                    changeHandler={(value: boolean) => setState({ ...state, mustChangePassword: value })}
                    checked={state.mustChangePassword}
                    disabled={create && !state.password}
                />
            </div>
            <div className="grid grid-cols-2 gap-2">
                <TextBox
                    id="firstName"
                    label="First Name"
                    value={state.firstName}
                    changeHandler={(value: string) => setState({ ...state, firstName: value })}
                    required
                />
                <TextBox
                    id="lastName"
                    label="Last Name"
                    value={state.lastName}
                    changeHandler={(value: string) => setState({ ...state, lastName: value })}
                    required
                />
            </div>
            <ToggleButton
                label="Active"
                values={[
                    { id: false, value: "Inactive" },
                    { id: true, value: "Active" },
                ]}
                value={state.active}
                changeHandler={(value: boolean) => setState({ ...state, active: value })}
                id="active"
            />

            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                <Checkbox
                    id="isOwner"
                    label="Is Owner"
                    inline={true}
                    changeHandler={(value: boolean) => setState({ ...state, isOwner: value })}
                    checked={state.isOwner}
                />
                {state.isOwner ? (
                    <TextBox
                        placeholder="Owner Code"
                        value={state.ownerCode}
                        changeHandler={(value: string) => {
                            if (value.length > 5) return;
                            setState({ ...state, ownerCode: value });
                        }}
                    />
                ) : null}
                {Object.keys(SelectableUserPermissionFlags).map((permission) => {
                    if (isNaN(Number(permission))) return null;

                    const enumVal = parseInt(permission);
                    const fieldVal = parseInt(state.permissionFlags?.toString() ?? "0");

                    const handleCheckboxChange = (value: boolean) => {
                        let newFieldVal: number;

                        if (value) newFieldVal = fieldVal ^ enumVal;
                        else newFieldVal = fieldVal & ~enumVal;

                        setState({ ...state, permissionFlags: newFieldVal });
                    };

                    return (
                        <Checkbox
                            key={enumVal}
                            id={`permissionFlags-${enumVal}`}
                            label={splitWordsOnUpperCase((SelectableUserPermissionFlags[enumVal] as string) ?? "")}
                            inline={true}
                            changeHandler={handleCheckboxChange}
                            checked={(fieldVal & enumVal) === enumVal}
                        />
                    );
                })}
            </div>
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={userMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={userMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
