import { useQuery } from "@tanstack/react-query";

import { ReportingApi } from "../../../api/reportingApi";
import { QueryKeys } from "../../../helpers";
import { useReplaceVerbiage } from "../../../hooks/verbiageHooks";
import { type ReportingFilter } from "../../../models";
import { MapChart } from "../../basic";

export interface Props {
    filterData: ReportingFilter;
}

export function RepLocationWidget(props: Props) {
    const { filterData } = props;

    const locationsQuery = useQuery({
        queryKey: [QueryKeys.REP_POSITIONS, filterData],
        queryFn: async () => {
            return await ReportingApi.getRepPositions(filterData);
        },
    });

    const replaceVerbiage = useReplaceVerbiage();

    return (
        <MapChart
            id="rep-locations"
            title={replaceVerbiage("Rep", "Rep Locations")}
            data={locationsQuery.data ?? []}
            keyFunc={(x) => x.tpvId}
            type="userPin"
        />
    );
}
