import { useMutation, useQuery } from "@tanstack/react-query";
import { type FC, type FormEvent, type ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserApi } from "../../api/loginApi";
import { UserContext } from "../../contexts/userContext";
import { QueryKeys, RouteValues, clearPayloadCookie } from "../../helpers";
import { Button, Spinner, TextBox, useErrorToast, useSuccessToast } from "../basic";

interface State {
    oldPassword: string;
    newPassword: string;
    passwordAgain: string;
}

interface Props {
    forgotKey?: string;
}

export const ChangePasswordForm: FC<Props> = ({ forgotKey }) => {
    const errorToast = useErrorToast();
    const { user } = useContext(UserContext);
    const [email, setEmail] = useState(user?.email);

    const userDataQuery = useQuery({
        queryKey: [QueryKeys.FORGOT_PASSWORD, forgotKey],
        queryFn: () => UserApi.getForgotPassword(forgotKey ?? ""),
        enabled: !!forgotKey,
    });

    useEffect(() => {
        if (!user && !forgotKey) errorToast("Change Password", "No matching user found.");
    }, [errorToast, forgotKey, user]);

    useEffect(() => {
        if (userDataQuery.data) setEmail(userDataQuery.data);
    }, [userDataQuery.data]);

    useEffect(() => {
        if (user?.mustChangePassword) errorToast("Required", "You must change your password.");
    }, [errorToast, user?.mustChangePassword]);

    useEffect(() => {
        if (user?.email) setEmail(user?.email);
    }, [user?.email]);

    return <ChangePasswordFormInner loading={userDataQuery.isLoading} forgotKey={forgotKey} email={email} />;
};

interface InnerProps {
    forgotKey?: string;
    email?: string;
    loading?: boolean;
}

export const ChangePasswordFormInner: FC<InnerProps> = ({ email, forgotKey, loading }) => {
    const [state, setState] = useState<State>({
        oldPassword: "",
        newPassword: "",
        passwordAgain: "",
    });
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();
    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        changePasswordMutation.mutate({
            email: email ?? "",
            oldPassword: forgotKey || state.oldPassword,
            newPassword: state.newPassword,
            forgotPassword: !!forgotKey,
        });
    };

    const changePasswordMutation = useMutation({
        mutationFn: UserApi.changePassword,
        onSuccess: (data) => {
            if (data.errorMessage) {
                errorToast("Change password", data.errorMessage);
            } else {
                clearPayloadCookie();
                navigate("/", { replace: true });
                successToast("Change password", "Password changed successfully");
            }
        },
        onError: () => {
            errorToast("Change password", "Password change failed");
        },
    });

    if (!email)
        return (
            <ChangePasswordWrapper handleSubmit={() => navigate(`/${RouteValues.LOGIN}`)}>
                <p className="subtitle text-red-600">No matching user found.</p>
                <p className="subtitle">Please log in.</p>
                {loading && <Spinner />}
                <div className="mt-6 flex items-center justify-center gap-x-6">
                    <Button type="submit" variant="default">
                        Login
                    </Button>
                </div>
            </ChangePasswordWrapper>
        );

    return (
        <ChangePasswordWrapper handleSubmit={handleSubmit}>
            <>
                <p className="subtitle">
                    Use a new unique password. You will be redirected to login upon successful password change.
                </p>
                <div className="mt-10 grid grid-cols-1 gap-2">
                    {!forgotKey ? (
                        <TextBox
                            id="oldPassword"
                            label="Old Password"
                            type="password"
                            value={state.oldPassword}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    oldPassword: event.target.value,
                                });
                            }}
                            required
                        />
                    ) : null}
                    <TextBox
                        id="newPassword"
                        label="New Password"
                        type="password"
                        value={state.newPassword}
                        onChange={(event) => {
                            setState({
                                ...state,
                                newPassword: event.target.value,
                            });
                        }}
                        required
                    />
                    <TextBox
                        id="passwordAgain"
                        label="Confirm New Password"
                        type="password"
                        value={state.passwordAgain}
                        onChange={(event) => {
                            setState({
                                ...state,
                                passwordAgain: event.target.value,
                            });
                        }}
                        required
                    />
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Button type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </>
        </ChangePasswordWrapper>
    );
};

interface ChangePasswordWrapperProps {
    handleSubmit?: (e: any) => void;
    children: ReactNode;
}

const ChangePasswordWrapper: FC<ChangePasswordWrapperProps> = ({ handleSubmit, children }) => {
    return (
        <form className="card h-full flex-1" onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="pb-12">
                    <h2 className="title">Change Password</h2>
                    {children}
                </div>
            </div>
        </form>
    );
};
