import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type FC, type FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ScanApi } from "../../api/scanApi";
import { CheckInContext } from "../../contexts/checkInContext";
import { QueryKeys, RouteValues } from "../../helpers";
import { ScanBatchDirection } from "../../models/scanBatchDirection";
import { ScanBatchMethod } from "../../models/scanBatchMethod";
import { Button, Select, type SelectOption, useErrorToast } from "../basic";
import { CampaignDropdown } from "../dropdowns/campaignDropdown";

interface State {
    campaignId: number | undefined;
    direction: ScanBatchDirection;
    method: ScanBatchMethod;
}

interface CreateCheckInProps {
    setOpenCreateDialog: (open: boolean) => void;
}

export const CreateCheckIn: FC<CreateCheckInProps> = ({ setOpenCreateDialog }) => {
    const [state, setState] = useState<State>({
        campaignId: undefined,
        direction: ScanBatchDirection.In,
        method: ScanBatchMethod.Barcode,
    });
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();
    const { setBatchId } = useContext(CheckInContext);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!state.campaignId) return;

        formMutation.mutate({
            campaignId: state.campaignId,
            direction: state.direction,
            method: state.method,
        });
    };

    const formMutation = useMutation({
        mutationFn: ScanApi.createBatch,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [QueryKeys.CHECK_IN] });
            setBatchId(data.id);
            navigate(`/${RouteValues.CHECK_IN}/${data.id}`);
        },
        onError: () => {
            errorToast("Start Check In/Out", "Starting a new check in/out has failed");
        },
    });

    return (
        <form className="form-dialog" onSubmit={handleSubmit}>
            <CampaignDropdown
                value={state.campaignId}
                selectVal={(value: SelectOption) => setState({ ...state, campaignId: value.value as number })}
                required
                chooseFirst
            />
            <Select
                label="Direction"
                value={state.direction}
                changeHandler={(value: SelectOption) =>
                    setState({ ...state, direction: value.value as ScanBatchDirection })
                }
                required
                options={Object.keys(ScanBatchDirection).map((key) => ({
                    label: `Check ${ScanBatchDirection[key as keyof typeof ScanBatchDirection]}`,
                    value: ScanBatchDirection[key as keyof typeof ScanBatchDirection],
                }))}
            />
            <Select
                label="Method"
                value={state.method}
                changeHandler={(value: SelectOption) => setState({ ...state, method: value.value as ScanBatchMethod })}
                required
                options={Object.keys(ScanBatchMethod).map((key) => ({
                    label: ScanBatchMethod[key as keyof typeof ScanBatchMethod],
                    value: ScanBatchMethod[key as keyof typeof ScanBatchMethod],
                }))}
            />
            <div className="mt-4 grid">
                <div className="grid grid-cols-2 gap-2">
                    <Button
                        isLoading={formMutation.isPending}
                        type="button"
                        variant="secondary"
                        onClick={() => setOpenCreateDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button isLoading={formMutation.isPending} type="submit" variant="default">
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
};
