import { useQuery } from "@tanstack/react-query";
import { type FC } from "react";

import { GridFiltersApi } from "../../api/gridFiltersApi";
import { QueryKeys } from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type DropdownProps, FilterDropdown } from "./filterDropDown";

interface Props extends DropdownProps {
    campaignIds?: number[];
}

export const RepsDropdown: FC<Props> = (props) => {
    const query = useQuery({
        queryKey: [QueryKeys.REPS, props.campaignIds],
        queryFn: () => GridFiltersApi.getRepresentatives(props.campaignIds),
    });

    const mapVerbiage = useMapVerbiage();

    return <FilterDropdown id="representativeId" label={mapVerbiage("Rep")} {...props} query={query} isSearchable />;
};
