import { differenceInDays } from "date-fns";
import { type FC, useMemo } from "react";

import { useReplaceVerbiage } from "../../../hooks/verbiageHooks";
import { type ReportingLocation, type VerificationDto } from "../../../models";
import { BarChart, HeatmapChart, type HeatmapData, MapChart, PieChart } from "../../basic";
import { type DataItem } from "../../basic/charts/types";

interface Props {
    data: VerificationDto[];
    loading: boolean;
}

function mapTryAdd(map: Map<string, number>, key: string) {
    const val = map.get(key) || 0;
    map.set(key, val + 1);
}

function mapVerToSaleOutcome(map: Map<string, number>, ver: VerificationDto) {
    const outcome = ver.dispositionName === "Good Sale" ? "Successful" : !ver.dispositionName ? "Pending" : "Failed";
    mapTryAdd(map, outcome);
}

function mapVerToNoSaleDispositions(map: Map<string, number>, ver: VerificationDto) {
    if (ver.dispositionName && ver.dispositionName !== "Good Sale") mapTryAdd(map, ver.dispositionName);
}

function mapToHeatMap(map: Map<string, number>, ver: VerificationDto) {
    if (!ver.dateOfSale) return;
    const key = `${new Date(ver.dateOfSale).toDateString()} - ${new Date(ver.dateOfSale).getHours()}`;
    mapTryAdd(map, key);
}

function getOpacity(days: number, date: Date) {
    const diff = differenceInDays(new Date(), date);
    if (diff > days) return 0;
    const opacity = Math.floor(((days - diff) / days) * 100);
    return isNaN(opacity) ? 0 : opacity;
}

export const RealTimeGraphs: FC<Props> = (props) => {
    const replaceVerbiage = useReplaceVerbiage();

    const days = useMemo(() => {
        if (!props.data?.length) return 0;
        const earliestPossibleDate = new Date(1, 1, 2020);
        let firstDate = new Date();
        let lastDate = new Date(1, 1, 2020);
        for (const v of props.data) {
            const date = new Date(v.dateOfSale ?? "");
            if (date < earliestPossibleDate) continue;
            if (date < firstDate) firstDate = date;
            if (date > lastDate) lastDate = date;
        }
        const diff = differenceInDays(lastDate, firstDate);
        return diff || 1;
    }, [props.data]);

    const filteredData = useMemo(() => {
        const saleOutcomes: DataItem[] = [];
        const noSaleDispositions: { name: string; data: DataItem[] }[] = [];
        const repPositions = new Map<string, VerificationDto[]>();
        const lastRepPositions: ReportingLocation[] = [];
        const tpvLocations: ReportingLocation[] = [];
        const heatmapData: HeatmapData[] = [];

        const saleOutcomesMap = new Map<string, number>();
        const noSaleDispositionsMap = new Map<string, number>();
        const heatmap = new Map<string, number>();

        props.data.forEach((item) => {
            mapVerToSaleOutcome(saleOutcomesMap, item);
            mapVerToNoSaleDispositions(noSaleDispositionsMap, item);

            if (item.position?.lat || item.position?.lon) {
                tpvLocations.push({
                    tpvId: item.id,
                    repId: item.repId,
                    repName: item.firstName + " " + item.lastName,
                    lat: item.position.lat,
                    lon: item.position.lon,
                    datetime: new Date(item.position.asOf),
                    disposition: item.dispositionName,
                    status: item.statusText,
                    client: item.channelCode,
                    channel: item.channelCode,
                    state: item.stateCode,
                    opacity: getOpacity(days, new Date(item.position?.asOf)),
                });
            }

            if (repPositions.has(item.repId)) repPositions.get(item.repId)?.push(item);
            else repPositions.set(item.repId, [item]);

            mapToHeatMap(heatmap, item);
        });

        repPositions.forEach((value) => {
            // the last item for each rep in the list should be their latest position
            // we can add sorting later if we need to but that that would be expensive
            const lastPosition = value.pop();

            if (lastPosition)
                lastRepPositions.push({
                    tpvId: lastPosition.id,
                    repId: lastPosition.repId,
                    repName: lastPosition.firstName + " " + lastPosition.lastName,
                    lat: lastPosition.enrollmentPosition.lat,
                    lon: lastPosition.enrollmentPosition.lon,
                    datetime: new Date(lastPosition.enrollmentPosition.asOf),
                    disposition: lastPosition.dispositionName,
                    status: lastPosition.statusText,
                    client: lastPosition.clientCode,
                    channel: lastPosition.channelCode,
                    state: lastPosition.stateCode,
                    opacity: getOpacity(days, new Date(lastPosition.position?.asOf)),
                });
        });

        saleOutcomes.push({ name: "Successful", y: saleOutcomesMap.get("Successful") ?? 0 });
        saleOutcomes.push({ name: "Failed", y: saleOutcomesMap.get("Failed") ?? 0 });
        saleOutcomes.push({ name: "Pending", y: saleOutcomesMap.get("Pending") ?? 0 });

        noSaleDispositionsMap.forEach((value, key) => {
            noSaleDispositions.push({ name: key, data: [{ name: key, y: value }] });
        });

        heatmap.forEach((value, key) => {
            const [date, time] = key.split(" - ");
            heatmapData.push({ date: new Date(date ?? ""), time: parseInt(time ?? "0"), z: value });
        });

        return { saleOutcomes, noSaleDispositions, tpvLocations, enrolledLocations: lastRepPositions, heatmapData };
    }, [days, props.data]);

    if (props.loading) return null;

    return (
        <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
            <PieChart id="sales-outcome-pie" title="TPV Results" data={filteredData.saleOutcomes} />
            <BarChart id="no-sales-bar" title="TPV Failure Dispositions" data={filteredData.noSaleDispositions} />
            <MapChart
                id="realtime-tpv-locations"
                title="TPV Locations"
                data={filteredData.tpvLocations}
                keyFunc={(x) => x.tpvId}
                type="flag"
                realtime
            />
            <MapChart
                id="realtime-rep-locations"
                title={replaceVerbiage("Rep", "Rep Locations")}
                data={filteredData.enrolledLocations}
                keyFunc={(x) => x.repId}
                type="userPin"
                realtime
            />
            <MapChart
                id="realtime-tpv-heatmap"
                title="TPV Heatmap"
                data={filteredData.tpvLocations}
                keyFunc={(x) => x.tpvId}
                type="heatmap"
            />
            <HeatmapChart id="heatmap" title="Completed TPVs" data={filteredData.heatmapData} />
        </div>
    );
};
