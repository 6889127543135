import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "highcharts/modules/accessibility";
import { type FC, useEffect, useRef, useState } from "react";

import { useBreakpoint } from "../../../helpers";
import "./charts.scss";
import { NoData } from "./noData";
import "./pie.scss";
import { type DataItem } from "./types";

interface PieChartProps extends HighchartsReact.Props {
    id: string;
    title: string;
    data: DataItem[];
}

export const PieChart: FC<PieChartProps> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const { isSm } = useBreakpoint("sm");

    useEffect(() => {
        if (!props.data || props.data.length === 0) return;

        setOptions({
            chart: {
                styledMode: true,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: props.title,
                align: "left",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
            },
            tooltip: {
                enabled: false,
                headerFormat: "",
                pointFormat: "",
            },
            legend: {
                enabled: isSm,
                labelFormat: "{name}: {y} ({percentage:.1f}%)",
            },
            series: [
                {
                    type: "pie",
                    data: props.data,
                    dataLabels: {
                        enabled: !isSm,
                        format: "{point.name}: {point.y} ({point.percentage:.1f}%)",
                    },
                    accessibility: {
                        point: {
                            valueDescriptionFormat: "{point.name}",
                        },
                        enabled: true,
                    },
                },
            ],
        });
    }, [isSm, props.data, props.title]);

    if (!options) return null;

    if (props.data[0]?.y === 0 && props.data[1]?.y === 0 && props.data[2]?.y === 0)
        return <NoData title={props.title} />;

    return (
        <div className="card pie-chart">
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
        </div>
    );
};
